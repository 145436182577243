<template>
  <div>
    <div class="ma-4" id="spreadsheet"></div>
      <input v-model="input.korrektur" @change="calculate" type="text">
    <div>{{data.angbotNettoKorrektur | currency}}</div>
    <div>{{data.mwstKorrektur | currency}}</div>
    <div>{{data.angebotBruttoKorrektur | currency}}</div>
  </div>
</template>

<script>
import 'jspreadsheet-ce/dist/jspreadsheet.css'

export default {
  name: "Sheet",
  data() {
    return {
      data: {
        totalHours: 0,
        workTotalPrice: 0.0,
        totalMaterialAdditionPercentage: 0,
        materialPreis: undefined,
        materialAufschlag: undefined,
        materialPreisGesamt: undefined,
        zuschlagFremdleistungen: undefined,
        fremdleistungenGesamt: undefined,
        verrechnungsSatz: undefined,
        angebotNetto: undefined,
        mwst: 0.0,
        angebotBrutto: undefined,
        angebotBruttoKorrektur: undefined,
        mwstKorrektur: undefined,
        angbotNettoKorrektur: undefined

      },
      input: {
        workingHoursPerField: [
          {hours: 40, days: 0, additionMaterial: 20},
          {hours: 40, days: 0, additionMaterial: 25},
          {hours: 28, days: 0, additionMaterial: 30},
          {hours: 25, days: 0, additionMaterial: 2},
          {hours: 0, days: 0, additionMaterial: 35},
          {hours: 25, days: 0, additionMaterial: 25},
        ],
        fremdLeistungen: 50000,
        aufschlagMaterial: 30,
        korrektur: 50
      },
      settings: {
        hoursPerDay: 8,
        hourlyWage: 59.20,
        aufschlagFremdleistungen: 25,
        mehrwertSteuerSatz: 19
      }
    }
  },
  methods: {
    calculate() {
      const customizedHourCalculator = activityHours(this.settings.hoursPerDay)
      this.input.workingHoursPerField = this.input.workingHoursPerField.map(field => ({
        ...field,
        totalHours: customizedHourCalculator(field.hours,field.days ),
      }))
      const totalHours = this.input.workingHoursPerField.reduce((acc, val) => acc + val.totalHours, 0)
      this.input.workingHoursPerField = this.input.workingHoursPerField.map(field => ({
        ...field,
        weightingTime: ((field.totalHours /  totalHours) * 100),
        weightingMaterial:  ((field.totalHours /  totalHours) * field.additionMaterial ),

      }))
      const workTotalPrice =  totalHours * this.settings.hourlyWage
      const totalMaterialAdditionPercentage = this.input.workingHoursPerField.reduce((acc, val) => acc +  val.weightingMaterial, 0)
      const materialPreis = workTotalPrice * (totalMaterialAdditionPercentage / 100)
      const materialAufschlag =  (materialPreis * this.input.aufschlagMaterial) / 100
      const materialPreisGesamt = materialPreis + materialAufschlag
      const zuschlagFremdleistungen = this.input.fremdLeistungen * (this.settings.aufschlagFremdleistungen / 100)
      const fremdleistungenGesamt = zuschlagFremdleistungen + this.input.fremdLeistungen
      const angebotNetto = workTotalPrice + materialPreisGesamt + fremdleistungenGesamt
      const mwst = angebotNetto *  (this.settings.mehrwertSteuerSatz / 100)
      const angebotBrutto = angebotNetto + mwst
      const angbotNettoKorrektur = angebotNetto * this.input.korrektur / 100
      const mwstKorrektur = angbotNettoKorrektur *  (this.settings.mehrwertSteuerSatz / 100)
      const angebotBruttoKorrektur = angbotNettoKorrektur + mwstKorrektur
      const verrechnungsSatz = (workTotalPrice + materialAufschlag + zuschlagFremdleistungen) / totalHours


      const result = {
        totalHours,
        workTotalPrice,
        totalMaterialAdditionPercentage,
        materialPreis,
        materialAufschlag,
        materialPreisGesamt,
        zuschlagFremdleistungen,
        fremdleistungenGesamt,
        verrechnungsSatz,
        angebotNetto,
        mwst,
        angebotBrutto,
        angebotBruttoKorrektur,
        mwstKorrektur,
        angbotNettoKorrektur
      }
      this.data = result
    }
  },
  filters: {
    currency: function (value) {
      if (!value) return ''
      console.log(value)
      return value.toFixed(2).replace(".", ",") + " €"
    }
  }
}
const activityHours = hoursPerDay => (hours, days) => hoursPerDay * days + hours
</script>

<style lang="scss" scoped>
  #spreadsheet {
    ::v-deep thead {
      display: none !important;
    }
  }
</style>
